import React, { useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import getBase64 from "../../../helpers/getBase64";
import { useTheme } from "@emotion/react";
import { t } from "i18next";

const fieldsToWatch = [
  "mallapati",
  "mallapati_other",
  "gcs",
  "gcs_other",
  "pulse",
  "ecg",
  "ecg_image",
  "echo",
  "echo_image",
  "cvs_other",
  "oral_intake_date",
  "oral_intake_other",
];

export default function PreAnaesthesia() {
  const { patientId, procedureId, age, gender } = useParams();
  const [viewMode, setViewMode] = useState();
  const [preAnaesthesia, setpreAnaesthesia] = useState();
  const [loading, setLoading] = useState(true);
  const [diagnosis, setDiagnosis] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [diagnosisType, setDiagnosisType] = useState("both");
  const [watchedFields, setWatchedFields] = useState();

  const navigate = useNavigate();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const addProcedurePreAnaesthesia = useApi(mainApi.addProcedurePreAnaesthesia);
  const getProcedureSurgerySideList = useApi(
    mainApi.getProcedureSurgerySideList
  );
  const getProcedureBloodGroupList = useApi(mainApi.getProcedureBloodGroupList);
  const getProcedureVirtualMarkerList = useApi(
    mainApi.getProcedureVirtualMarkerList
  );
  const getDrugList = useApi(mainApi.getDrugList);
  const getProcedureAnaesthesiaTypeList = useApi(
    mainApi.getProcedureAnaesthesiaTypeList
  );
  const getProcedureMallapatiList = useApi(mainApi.getProcedureMallapatiList);
  const getProcedureAsascoreList = useApi(mainApi.getProcedureAsascoreList);
  const getProcedurePreAnaesthesia = useApi(mainApi.getProcedurePreAnaesthesia);

  const handleProcedureView = async () => {
    const res = await mainApi.getProcedurePreAnaesthesia(
      patientId,
      procedureId
    );
    if (res.ok) {
      if (res.data?.data) {
        setpreAnaesthesia(res.data.data);
        setViewMode("list");
      } else {
        setViewMode("form");
      }
      setLoading(false);
    }
  };
  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  useEffect(() => {
    handleProcedureView();
  }, [addProcedurePreAnaesthesia.data]);

  useEffect(() => {
    if (viewMode === "form") {
      getFilteredDiseases.request("*");
      getProcedureSurgerySideList.request();
      getProcedureBloodGroupList.request();
      getProcedureVirtualMarkerList.request();
      getDrugList.request(`?search=*`);
      getProcedureAnaesthesiaTypeList.request();
      getProcedureMallapatiList.request();
      getProcedureAsascoreList.request();
    }
    if (viewMode === "list") {
      getProcedurePreAnaesthesia.request(patientId, procedureId);
    }
  }, [viewMode]);

  useEffect(() => {
    if (diagnosisType === "both") {
      setDiagnosis(getFilteredDiseases.data);
      setMedicalHistory(getFilteredDiseases.data);
    } else if (diagnosisType === "diagnosis") {
      setDiagnosis(getFilteredDiseases.data);
    } else {
      setMedicalHistory(getFilteredDiseases.data);
    }
  }, [getFilteredDiseases.data, diagnosisType]);

  const filterDiseaseHandler = async (value, type) => {
    await getFilteredDiseases.request(value);
    setDiagnosisType(type);
  };

  const filterDrugsHandler = (value) => {
    getDrugList.request(`?search=${value}`);
  };

  const fields = [
    {
      type: "readOnly",
      label: "Date",
      name: "date",
      value: new Date().toLocaleString(),
    },
    {
      type: "select",
      label: "Diagnosis",
      name: "diagnosis",
      items: diagnosis,
      autoComplete: true,
      disableFilter: true,
      onInputChange: (value) => filterDiseaseHandler(value, "diagnosis"),
    },
    {
      label: "Surgery Site",
      name: "surgery_site",
    },
    {
      label: "Surgery Side",
      name: "surgery_side",
      type: "select",
      items: getProcedureSurgerySideList.data,
    },
    {
      label: "Height (m)",
      name: "height",
      type: "decimal",
    },
    {
      label: "Weight (kg)",
      name: "weight",
      type: "decimal",
    },
    {
      type: "select",
      label: "Blood Group",
      name: "blood_group",
      items: getProcedureBloodGroupList.data,
    },
    {
      type: "select",
      label: "Viral Markers",
      name: "virtual_markers",
      multiple: true,
      autoComplete: true,
      items: getProcedureVirtualMarkerList.data,
      validation: { required: true },
      selectAll: true,
    },

    {
      label: "Allergy",
      name: "allergies",
      multipleRecords: true,
    },
    {
      label: "Medical History",
      name: "medical_history",
      type: "select",
      items: medicalHistory,
      autoComplete: true,
      multiple: true,
      onInputChange: (value) => filterDiseaseHandler(value, "medicalHistory"),
    },

    {
      label: "Medications",
      name: "medications",
      type: "select",
      isEnum: true,
      autoComplete: true,
      multiple: true,
      disableFilter: true,
      items: getDrugList.data?.map((item) => item.name),
      onInputChange: (value) => filterDrugsHandler(value),
    },
    {
      fieldType: "nested",
      nestedId: 1,
      title: "Surgical History",
      name: "surgical_history",
      primaryListText: {
        key: "Surgery",
        value: "field.surgery + ', ' + field.date",
      },
      secondaryListText: {
        key: "Type of Anaesthesia",
        value: "field.anaesthesia_type",
      },
      fields: [
        { name: "surgery", label: "Surgery" },
        {
          type: "date",
          name: "date",
          label: "Date",
          validation: { disableFuture: true, required: true },
        },
        {
          label: "Type of Anaesthesia",
          name: "anaesthesia_type",
          type: "select",
          items: getProcedureAnaesthesiaTypeList.data,
          validation: { required: true },
        },
        {
          label: "Anaesthesia Complications",
          name: "anaesthesia_complications",
        },
      ],
    },

    {
      relatedFields: true,
      displayCondition: true,
      fieldsTitle: "Airway Assessment",
      fields: [
        {
          twoFieldsInRow: true,
          childField: true,
          fields: [
            {
              type: "select",
              label: "Mallapati Score",
              name: "mallapati",
              items: getProcedureMallapatiList.data,
              width: "150px",
            },
            {
              name: "mallapati_other",
              label: "Other",
              width: "150px",
            },
          ],
        },
      ],
    },

    {
      type: "select",
      name: "asa_score",
      label: "Asa Score",
      items: getProcedureAsascoreList.data,
    },
    {
      fieldType: "labelField",
      label: "Systematic Examination",
    },
    {
      type: "decimal",
      name: "temperature",
      label: "Temperature",
    },
    {
      relatedFields: true,
      displayCondition: true,
      fieldsTitle: "CNS",
      fields: [
        {
          twoFieldsInRow: true,
          fields: [
            {
              type: "number",
              name: "gcs",
              label: "GCS",
              width: "150px",
              validation: {
                minValue: 3,
                maxValue: 15,
              },
            },
            {
              name: "gcs_other",
              label: "Other",
              width: "150px",
            },
          ],
        },
      ],
    },
    {
      relatedFields: true,
      displayCondition: true,
      fieldsTitle: "CVS",
      fields: [
        {
          name: "pulse",
          label: "Pulse",
          type: "number",
          validation: { positive: true },
        },
        {
          twoFieldsInRow: true,
          fields: [
            { label: "ECG", name: "ecg", xs: 9 },
            {
              label: "",
              type: "file",
              name: "ecg_image",
              acceptFiles: [
                "image/jpg",
                "image/png",
                "image/jpeg",
                ".pdf",
                "application/pdf",
                ".doc",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ],
              xs: 3,
            },
          ],
        },
        {
          twoFieldsInRow: true,
          fields: [
            { label: "ECHO", name: "echo", xs: 9 },
            {
              label: "",
              type: "file",
              name: "echo_image",
              acceptFiles: [
                "image/jpg",
                "image/png",
                "image/jpeg",
                ".pdf",
                "application/pdf",
                ".doc",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ],
              xs: 3,
            },
          ],
        },
        {
          name: "cvs_other",
          label: "Other",
        },
      ],
    },

    {
      type: "number",
      name: "respiratory_rate",
      label: "Respiratory Rate",
      validation: { positive: true },
    },
    { multiline: true, name: "git_hepatobiliary", label: "Git Hepatobiliary" },
    { multiline: true, name: "renal", label: "Renal" },
    {
      multiline: true,
      name: "peripheral_vascular_disease",
      label: "Peripheral Vascular Disease",
    },
    { multiline: true, name: "musculoskeletal", label: "Musculoskeletal" },
    { multiline: true, name: "endocrine", label: "Endocrine" },
    {
      multiline: true,
      name: "other",
      label: "Other",
    },
    {
      relatedFields: true,
      displayCondition: true,
      fieldsTitle: "Last Oral Intake",
      fields: [
        {
          twoFieldsInRow: true,
          fields: [
            {
              name: "oral_intake_date",
              type: "date",
              label: "Date",
              width: "180px",
              validation: { disableFuture: true },
            },
            {
              name: "oral_intake_type",
              label: "Type",
              width: "200px",
            },
          ],
        },
      ],
    },
  ];

  const data = [
    {
      label: "Date",
      data: moment(getProcedurePreAnaesthesia.data?.created_at).format(
        "DD-MM-YYYY"
      ),
    },
    {
      label: "Diagnosis",
      data: getProcedurePreAnaesthesia.data?.diagnosis?.label,
    },
    {
      label: "Surgery Site",
      data: getProcedurePreAnaesthesia.data?.surgery_site,
    },
    {
      label: "Surgery Side",
      data: getProcedurePreAnaesthesia.data?.surgery_side?.label,
    },
    {
      label: "Height (m)",
      data: getProcedurePreAnaesthesia.data?.height,
    },
    {
      label: "Weight (kg)",
      data: getProcedurePreAnaesthesia.data?.weight,
    },
    {
      label: "Blood Group",
      data: getProcedurePreAnaesthesia.data?.blood_group,
    },
    {
      label: "Viral Markers",
      data: getProcedurePreAnaesthesia.data?.virtual_markers?.map(
        (item) => item.label
      ),
    },

    {
      label: "Allergy",
      data: getProcedurePreAnaesthesia.data?.allergies?.map(
        (item) => item.text
      ),
    },
    {
      label: "Medical History",
      data: getProcedurePreAnaesthesia.data?.medical_history?.map(
        (item) => item.label
      ),
    },

    {
      label: "Medications",
      data: getProcedurePreAnaesthesia.data?.medications?.map(
        (item) => item.medication
      ),
    },
    {
      label: "Surgical History",
      data: getProcedurePreAnaesthesia.data?.surgical_history?.map(
        (item) =>
          `${item.surgery ? item.surgery : ""} , ${moment(item.date).format(
            "DD-MM-YYYY"
          )} , ${item.anaesthesia_type.label} , ${
            item.anaesthesia_complications ? item.anaesthesia_complications : ""
          }`
      ),
    },

    {
      label: "Airway Assessment",
      data:
        getProcedurePreAnaesthesia.data?.airway_assessment &&
        `${
          getProcedurePreAnaesthesia.data?.airway_assessment?.mallapati.label
        }${
          getProcedurePreAnaesthesia.data?.airway_assessment?.other
            ? ` - ${getProcedurePreAnaesthesia.data?.airway_assessment?.other}`
            : ""
        }`,
    },

    {
      label: "Asa Score",
      data: getProcedurePreAnaesthesia.data?.asa_score?.label,
    },
    {
      fieldType: "labelField",
      data: "Systematic Examination",
    },
    {
      label: "Temperature",
      data: `${
        getProcedurePreAnaesthesia.data?.temperature
          ? getProcedurePreAnaesthesia.data?.temperature
          : ""
      }`,
    },
    {
      label: "CNS",
      data: `${
        getProcedurePreAnaesthesia.data?.cns
          ? `${getProcedurePreAnaesthesia.data?.cns.gcs}${
              getProcedurePreAnaesthesia.data?.cns.other
                ? ` - ${getProcedurePreAnaesthesia.data?.cns.other}`
                : ""
            }`
          : ""
      }`,
    },
    {
      fieldType: "labelField",
      data: "CVS",
    },
    {
      label: "Pulse",
      data: getProcedurePreAnaesthesia.cvs?.pulse,
      halfRow: true,
    },
    {
      label: "Other",
      data: getProcedurePreAnaesthesia.cvs?.other,
      halfRow: true,
    },
    {
      label: "ECG",
      data: getProcedurePreAnaesthesia.cvs?.ecg,
      halfRow: true,
    },
    {
      label: "ECG image",
      fieldType: "link",
      data: getProcedurePreAnaesthesia.cvs?.ecg_image,
      halfRow: true,
    },
    {
      label: "ECHO",
      data: getProcedurePreAnaesthesia.cvs?.echo,
      halfRow: true,
    },
    {
      label: "ECHO image",
      fieldType: "link",
      data: getProcedurePreAnaesthesia.cvs?.echo_image,
      halfRow: true,
    },
    {
      label: "Respiratory Rate",
      data: getProcedurePreAnaesthesia.data.respiratory_rate,
    },
    {
      label: "Oral Intake",
      data: `${
        getProcedurePreAnaesthesia.data.oral_intake
          ? `${moment(getProcedurePreAnaesthesia.data.oral_intake.date).format(
              "DD-MM-YYYY"
            )}${
              getProcedurePreAnaesthesia.data.oral_intake.type
                ? ` , ${getProcedurePreAnaesthesia.data.oral_intake.type}`
                : ""
            }`
          : ""
      }`,
    },
    {
      label: "Git Hepatobiliary",
      data: getProcedurePreAnaesthesia.data.git_hepatobiliary,
    },
    { label: "Renal", data: getProcedurePreAnaesthesia.data.renal },
    {
      label: "Peripheral Vascular Disease",
      data: getProcedurePreAnaesthesia.data.peripheral_vascular_disease,
    },
    {
      label: "Musculoskeletal",
      data: getProcedurePreAnaesthesia.data.musculoskeletal,
    },
    { label: "Endocrine", data: getProcedurePreAnaesthesia.data.endocrine },
    {
      label: "Other",
      data: getProcedurePreAnaesthesia.data.other,
    },
  ];

  const onSubmit = async (data) => {
    const obj = {
      ...data,
      diagnosis: data.diagnosis?.id,
      virtual_markers: data.virtual_markers?.map((item) => item.id),
      allergies: data.allergies?.map((item) => ({ text: item })),
      medical_history: data.medical_history?.map((item) => item.id),
      medications: data.medications?.map((item) => ({ medication: item })),
    };

    if (data.mallapati || data.mallapati_other) {
      obj["airway_assessment"] = {
        mallapati: data.mallapati,
        other: data.mallapati_other,
      };
    }
    if (data.gcs || data.gcs_other) {
      obj["cns"] = {
        gcs: data.gcs,
        other: data.gcs_other,
      };
    }
    if (data.puls) {
      obj["cvs"] = {
        pulse: data.pulse,
        ecg: data.ecg,
        echo: data.echo,
        other: data.cvs_other,
        ecg_image: data.ecg_image[0] && (await getBase64(data.ecg_image[0])),
        echo_image: data.echo_image[0] && (await getBase64(data.echo_image[0])),
      };
    }
    if (data.oral_intake_date) {
      obj["oral_intake"] = {
        date: data.oral_intake_date,
        type: data.oral_intake_type,
      };
    }

    const res = await addProcedurePreAnaesthesia.requestWithToast(
      t("Added successfully"),
      patientId,
      procedureId,
      obj
    );

    if (res.ok) {
      setViewMode("list");
    }
  };

  return (
    <>
      {loading && <LoadingBox loading={true} />}
      {viewMode === "form" && !mobileView && (
        <CebForm
          onSubmit={onSubmit}
          fields={fields}
          viewModeState={[viewMode, setViewMode]}
          formWidth="90%"
          cancelHandler={() =>
            navigate(`/patients/${patientId}/${age}/${gender}/procedures`)
          }
          fieldsToWatch={{ name: fieldsToWatch }}
          getWatchedFields={getWatchedFields}
          requestErrors={addProcedurePreAnaesthesia.error}
        />
      )}
      {viewMode === "list" && (
        <Box
          // width={970}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            borderColor: "#00346880",
            p: 2,
          }}
        >
          <HierarchicalList data={data} />
        </Box>
      )}
    </>
  );
}

const HierarchicalList = ({ data = [] }) => {
  const getValue = (label) => {
    const field = data?.find((f) => f?.label === label);
    return field?.data;
  };

  const getCNSData = () => {
    const cnsField = data?.find((f) => f?.label === "CNS");
    if (!cnsField?.data) return null;

    try {
      const [gcs, other] = cnsField.data.split(" - ");
      return {
        gcs: gcs || "N/A",
        other: other || "N/A",
      };
    } catch (e) {
      return {
        gcs: "N/A",
        other: "N/A",
      };
    }
  };

  const getCVSData = () => {
    return {
      pulse: getValue("Pulse"),
      ecg: getValue("ECG"),
      echo: getValue("ECHO"),
      other: getValue("CVS Other"),
    };
  };

  const renderValue = (value) => {
    if (!value) return "N/A";
    if (Array.isArray(value)) {
      return value.length > 0 ? value.join(", ") : "N/A";
    }
    return value;
  };

  const renderField = (label, value, indent = 0, isBold = false) => (
    <div
      className="flex items-center py-2 border-b border-gray-200"
      style={{
        paddingLeft: `${indent * 1}rem`,
        display: "flex",
        paddingTop: 2,
        alignItems: "center",
        flex: 1,
        width: "60%",
        justifyContent: "space-between",
        color: "#003468",
      }}
    >
      <span
        className="font-medium text-gray-700 min-w-[200px]"
        style={{ fontWeight: isBold && "bolder", color: isBold && "#003468" }}
      >
        {label}:
      </span>
      <div
        className=" ml-4"
        style={{ fontWeight: "lighter", flex: 1, marginLeft: 40 }}
      >
        {renderValue(value)}
      </div>
    </div>
  );

  const renderNestedSection = (title, fields, indent = 0) => (
    <div className="w-full" style={{ paddingLeft: `${indent * 1}rem` }}>
      <div className="font-medium text-gray-700 py-2" style={{   color: "#003468",}}>{title}</div>
      {fields}
    </div>
  );

  const renderSection = (title, fields) => (
    <div className="mb-5">
      <div
        style={{
          fontWeight: "bolder",
          color: "#003468",
          marginBottom: 4,
          paddingLeft: 4,
          paddingTop: 10,
        }}
        className="bg-gray-400 py-3 px-4 font-semibold text-gray-800"
      >
        {title}
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        {fields}
      </div>
    </div>
  );

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <Card>
        <CardContent>
          <div className="text-center py-4 text-gray-500">
            No data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const cnsData = getCNSData();
  const cvsData = getCVSData();

  return (
    <Card>
      <CardContent>
        {/* Basic Information */}
        {
          <>
            {renderField("Date", getValue("Date"), undefined, true)}
            {renderField("Diagnosis", getValue("Diagnosis"), undefined, true)}
            {renderField(
              "Surgery Site",
              getValue("Surgery Site"),
              undefined,
              true
            )}
            {renderField(
              "Surgery Side",
              getValue("Surgery Side"),
              undefined,
              true
            )}
            {renderField("Height (m)", getValue("Height (m)"), undefined, true)}
            {renderField(
              "Weight (kg)",
              getValue("Weight (kg)"),
              undefined,
              true
            )}
            {renderField(
              "Blood Group",
              getValue("Blood Group"),
              undefined,
              true
            )}
            {renderField(
              "Viral Markers",
              getValue("Viral Markers"),
              undefined,
              true
            )}
            {renderField("Allergy", getValue("Allergy"), undefined, true)}
            {renderField(
              "Medical History",
              getValue("Medical History"),
              undefined,
              true
            )}
            {renderField(
              "Medications",
              getValue("Medications"),
              undefined,
              true
            )}
          </>
        }
        {/* Surgical History */}
        {renderSection(
          "Surgical History",
          <>
            {renderField("Surgery", getValue("Surgery "))}
            {renderField("Date", getValue("Date"))}
            {renderField("Type of anaethesia", getValue("Type of anaethesia"))}
            {renderField(
              "Anaethesia complications",
              getValue("Anaethesia complications")
            )}
          </>
        )}
        {renderSection(
          "Airway Assessment",
          <>
            {renderField("Mallapati score ", getValue("Airway Assessment"))}
            {renderField("Other", getValue("Mallapati Other"))}
          </>
        )}
        {renderField("ASA Score", getValue("Asa Score"),undefined,true)}

        {/* Systematic Examination */}
        {renderSection(
          "Systematic Examination",
          <>
            {renderField("Temperature", getValue("Temperature"))}
            {/* CNS Section */}
            {renderNestedSection(
              "CNS",
              <>
                {renderField("GCS", cnsData?.gcs, 1)}
                {renderField("Other", cnsData?.other, 1)}
              </>
            )}

            {/* CVS Section */}
            {renderNestedSection(
              "CVS",
              <>
                {renderField("Pulse", cvsData.pulse, 1)}
                {renderField("ECG", cvsData.ecg, 1)}
                {renderField("ECHO", cvsData.echo, 1)}
                {renderField("Other", cvsData.other, 1)}
              </>
            )}

            {renderField("Respiratory Rate", getValue("Respiratory Rate"))}
            {renderField("Git Hepatobiliary", getValue("Git Hepatobiliary"))}
            {renderField("Renal", getValue("Renal"))}
            {renderField(
              "Peripheral Vascular Disease",
              getValue("Peripheral Vascular Disease")
            )}
            {renderField("Musculoskeletal", getValue("Musculoskeletal"))}
            {renderField("Endocrine", getValue("Endocrine"))}
            {renderField("Other", getValue("Other"))}
          </>
        )}

        {/* Oral Intake */}
        {renderSection(
          "Last Oral Intake",
          <>
            {renderField("Date", getValue("Oral Intake").split(",")[0])}
            {renderField("Type", getValue("Oral Intake").split(",")[1])}
          </>
        )}
      </CardContent>
    </Card>
  );
};
