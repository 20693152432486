import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../components/Form/CebForm";
import { Context } from "../../context/Context";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import { getDataArr, getFields } from "./OrdersPage";
import Modal from "../../components/UI/Modal";
import auth from "../../auth/authService";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
  Box,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DrugsForm from "../../components/patient/DrugsForm";
import DrugsDetailView from "../../components/patient/DrugsDetailView";
import conditionalObj from "../../helpers/getConditionalObj";
import useDiseasesField from "../../hooks/useDiseasesFields";
import GeneralExamination from "./generalExamination/index";
import GeneralExaminationDetailView from "./generalExamination/DetailView";
import usePermissions from "../../hooks/usePermissions";
import moment from "moment";
import Print from "../../components/Print";
import SpecialExamination, {
  SpecialExaminationDetailView,
} from "../../components/patient/SpecialExamination";
import { t } from "i18next";

const module = "Encounter";
function formatDate(date) {
  // Get parts of the date
  const month = date.getMonth() + 1; // Month (number 1-12)
  const day = date.getDate(); // Day of the month (e.g., 28)
  const year = date.getFullYear(); // Year (e.g., 2024)

  let hours = date.getHours(); // Hours (24-hour format)
  const minutes = date.getMinutes(); // Minutes
  const ampm = hours >= 12 ? "PM" : "AM"; // AM or PM

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set it to 12
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero to minutes if needed

  // Format the final string
  const formattedDate = `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;

  return formattedDate;
}

export default function EncountersPage() {
  const navigate = useNavigate();
  const getDiseasesField = useDiseasesField();
  const { patientId, gender, age } = useParams();
  const { viewModeKey } = useContext(Context);
  const [viewMode, setViewMode] = viewModeKey;
  const [watchedFields, setWatchedFields] = useState([]);
  const [listData, setListData] = useState([]);
  const [encounterData, setEncounterData] = useState({ data: [], type: "" });
  const [diseases, setDiseases] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [ordersWatchedFields, setOrdersWatchedFields] = useState([]);
  const [ordersArr, setOrdersArr] = useState([]);
  const [prescriptionArr, setPrescriptionArr] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [generalExaminationId, setGeneralExaminationId] = useState(null);
  const [specialExaminationId, setSpecialExaminationId] = useState(null);
  const [encounterViewMode, setEncounterViewMode] = useState("encounter");
  const [selectedOrderType, setSelectedOrderType] = useState({});
  const [chosenCardId, setChosenCardId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  //Enums
  const encounterTypes = ["Clinic Visit", "Phone Call", "Progress Note"];

  //api
  const getEncountersList = useApi(mainApi.getEncountersList);
  const getEncounterPhoneCall = useApi(mainApi.getEncounterPhoneCall);
  const getEncounterProgressNote = useApi(mainApi.getEncounterProgressNote);
  const getEncounterClinicVisit = useApi(mainApi.getEncounterClinicVisit);
  const getOrder = useApi(mainApi.getOrder);
  const getDiseases = useApi(mainApi.getDiseases);
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const addEncounterPhoneCall = useApi(mainApi.addEncounterPhoneCall);
  const addEncounterProgressNote = useApi(mainApi.addEncounterProgressNote);
  const addEncounterClinicVisit = useApi(mainApi.addEncounterClinicVisit);
  const createGeneralExamination = useApi(mainApi.createGeneralExamination);
  const createEncounerSpecialExamination = useApi(
    mainApi.createEncounerSpecialExamination
  );
  const getSpecialExaminationForm = useApi(mainApi.getSpecialExaminationForm);

  //orders
  const getOrderTypesList = useApi(mainApi.getOrderTypesList);
  const getOrderSubTypesList = useApi(mainApi.getOrderSubTypesList);

  //permissions
  const permissions = usePermissions(module);

  useEffect(() => {
    setViewMode("cards");
    getDiseases.request();
    getOrderTypesList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "form") {
      getSpecialExaminationForm.request();
    }
  }, [viewMode]);

  useEffect(() => {
    if (permissions?.view) getEncountersList.request(patientId, pageNumber);
  }, [
    addEncounterClinicVisit.data,
    addEncounterPhoneCall.data,
    addEncounterProgressNote.data,
    permissions,
    pageNumber,
  ]);

  ///Diseases list handling
  useEffect(() => {
    setDiseases(getDiseases.data);
  }, [getDiseases.data]);

  useEffect(() => {
    setDiseases(getFilteredDiseases.data);
  }, [getFilteredDiseases.data]);

  const handleInputChange = (value) => {
    getFilteredDiseases.request(value);
  };
  ///Diseases list handling

  useEffect(() => {
    if (ordersWatchedFields[0] !== undefined && ordersWatchedFields[0] !== "") {
      getOrderSubTypesList.request(JSON.parse(ordersWatchedFields[0]).id); /// because of useFieldArray, at some point the watched field value equals []
      setSelectedOrderType(JSON.parse(ordersWatchedFields[0]));
    }
  }, [ordersWatchedFields]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const handleOpenPrescriptionModal = () => {
    setOpenPrescriptionModal((state) => !state);
  };

  const onPrescriptionAdd = () => {
    handleOpenPrescriptionModal();
  };

  const onOrderAdd = () => {
    handleOpenModal();
  };

  const handleGeneralExamination = async () => {
    if (!generalExaminationId) {
      const res = await createGeneralExamination.request(patientId);
      if (res?.ok) {
        setGeneralExaminationId(res.data.data.id);
        setEncounterViewMode("generalExamination");
      }
    } else setEncounterViewMode("generalExamination");
  };
  const handleSpecialExamination = async () => {
    if (!specialExaminationId) {
      const res = await createEncounerSpecialExamination.request();
      if (res?.ok) {
        setSpecialExaminationId(res.data.data.id);
        setEncounterViewMode("specialExamination");
      }
    } else setEncounterViewMode("specialExamination");
  };
  console.log("encounterData", encounterData);
  const encounterCardItems = [
    { header: "Type", keyName: "data.type" },
    { header: "Specialty", keyName: "data.specialty" },
    { header: "Doctor", keyName: "data.doctor" },
    { header: "Location", keyName: "data.location" },
    {
      header: "Date",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY",
    },
  ];

  const data = [
    {
      condition: encounterData.type !== "Order",
      label: "Visit Type",
      data: encounterData.type,
      cardItem: true,
    },
    {
      condition: encounterData.type !== "Order",
      label: "Specialty",
      data: encounterData.data.practitioner?.specialty?.name,
      cardItem: true,
    },
    {
      condition: encounterData.type !== "Order",
      label: "Doctor",
      data: encounterData.data.practitioner?.full_name,
      cardItem: true,
    },
    {
      condition: encounterData.type !== "Order",
      label: "Date",
      data: encounterData.data.created_at?.split("T")[0],
      cardItem: true,
    },
    conditionalObj(encounterData.type === "Clinic Visit", {
      label: "Complain",
      data: encounterData.data.complain,
      multiline: true,
    }),
    conditionalObj(
      encounterData.type === "Phone Call" ||
        encounterData.type === "Progress Note",
      {
        label: "Diagnosis",
        data: encounterData.data.diagnosis?.map((item) => item.label),
      }
    ),
    {
      condition: encounterData.type !== "Order",
      label: "Orders",
      data:
        encounterData.data.orders?.length > 0
          ? encounterData.data.orders?.map((item) => item.name)
          : null,
    },
    conditionalObj(
      encounterData.type === "Clinic Visit" &&
        encounterData.data.general_examination,
      {
        label: "General Examination",
        fieldType: "element",
        element: (
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => setEncounterViewMode("generalExamination")}
            variant="contained"
          >
            Show General Examination
          </Button>
        ),
      }
    ),
    conditionalObj(
      encounterData.type === "Clinic Visit" &&
        encounterData.data.special_examination,
      {
        label: "Special Examination",
        fieldType: "element",
        element: (
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => setEncounterViewMode("specialExamination")}
            variant="contained"
          >
            {t("Show Special Examination")}
          </Button>
        ),
      }
    ),
    {
      condition:
        encounterData.type !== "Order" &&
        encounterData.data.prescription?.drugs?.length > 0,
      label: "Prescription",
      fieldType: "element",
      element: (
        <Stack>
          {encounterData.data.prescription?.drugs?.map((item) => (
            <Box
              key={item.id}
              // sx={{ textDecoration: "underline", cursor: "pointer" }}
              sx={{ marginBottom: 2 }}
            >
              <Typography>
                <span>&#8226;</span>{" "}
                {`${item.generic_name}, ${item.route_administration} , ${item.num_units} units`}
              </Typography>
              <Typography>{`${item.meal_relation}, ${item.frequency_rate} doses/${item.frequency_type}`}</Typography>
              <Typography>
                {/* <span>&#8226;</span>{" "} */}
                {`Starting from: ${formatDate(new Date(item.starting_from))} for ${
                  item.rate
                } ${item.rate_type}`}
              </Typography>
              <Typography>
                {/* <span>&#8226;</span>{" "} */}
                {`Comments: ${item.comments}`}
              </Typography>
            </Box>
          ))}
        </Stack>
      ),
    },
    encounterData.data.reason && {
      label: "Reason",
      data: encounterData.data.reason,
      multiline: true,
    },
    encounterData.data.impression && {
      label: "Impression",
      data: encounterData.data.impression,
      multiline: true,
    },
    {
      condition: encounterData.type !== "Order",
      label: "Notes",
      data: encounterData.data.notes,
      multiline: true,
    },
    //encounter of type order  date fields
  ].filter((item) => item);

  const fields = [
    {
      isEnum: true,
      items: encounterTypes,
      type: "select",
      label: "Encounter Type",
      name: "encounterType",
    },
    {
      type: "readOnly",
      label: "Specialty",
      value: auth.getCurrentUser()?.specialty,
    },
    {
      type: "readOnly",
      label: "Name of professional",
      value: auth.getCurrentUser()?.full_name,
    },
    {
      relatedFields: true,
      fieldsTitle: watchedFields[0],
      displayCondition:
        watchedFields[0] === "Phone Call" ||
        watchedFields[0] === "Progress Note",
      fields: [
        { childField: true, label: "Reason", name: "reason", multiline: true },
        {
          childField: true,
          multiple: true,
          autoComplete: true,
          disableFilter: true,
          onInputChange: handleInputChange,
          items: diseases,
          type: "select",
          label: "Diagnosis",
          name: "diagnosis",
        },
        {
          childField: true,
          label: "Impression",
          name: "impression",
          multiline: true,
        },
        {
          childField: true,
          label: "Notes",
          name: "phoneNotes",
          multiline: true,
        },
      ],
    },
    {
      relatedFields: true,
      fieldsTitle: "Clinic Visit",
      displayCondition: watchedFields[0] === "Clinic Visit",
      fields: [
        {
          childField: true,
          label: "Complain",
          name: "complain",
          multiline: true,
        },
        {
          childField: true,
          type: "button",
          width: 197,
          label: "General Examination",
          buttonTitle: "Open",
          onClick: handleGeneralExamination,
        },
        getSpecialExaminationForm.loading
          ? {
              childField: true,
              type: "button",
              width: 197,
              label: "Special Examination",
              buttonTitle: "Loading...",
            }
          : getSpecialExaminationForm.data?.length > 0 && {
              childField: true,
              type: "button",
              width: 197,
              label: "Special Examination",
              buttonTitle: "Open",
              onClick: handleSpecialExamination,
            },
        // {childField: true, type: 'button', label: 'General Examination', buttonTitle: 'Open', onClick: () => navigate(`/patients/${patientId}/${age}/${gender}/encounters/GA/`)},
        {
          childField: true,
          label: "Notes",
          name: "clinicNotes",
          multiline: true,
        },
      ].filter((item) => item),
    },
    {
      fieldType: "buttons",
      fields: [
        { title: t("Prescription"), onClick: onPrescriptionAdd },
        { title: t("Order"), onClick: onOrderAdd },
      ],
    },
  ];

  const phoneCallObj = ({ reason, diagnosis, impression, phoneNotes }) => {
    return {
      reason,
      impression,
      notes: phoneNotes,
      diagnosis: diagnosis.map((item) => item.id),
      // entity_department: 1,
    };
  };

  const clinicVisitObj = ({ complain, clinicNotes }) => {
    return { complain, notes: clinicNotes, entity_department: 1 };
  };

  const handleViewCard = (encounter) => {
    setChosenCardId(encounter.id);
    setViewMode("list");
    if (encounter.type === "Phone Call")
      getEncounterPhoneCall.request(patientId, encounter.id);
    if (encounter.type === "Progress Note")
      getEncounterProgressNote.request(patientId, encounter.id);
    else if (encounter.type === "Clinic Visit")
      getEncounterClinicVisit.request(patientId, encounter.id);
    else if (encounter.type === "Order")
      getOrder.request(patientId, 0, encounter.id);
  };

  useEffect(() => {
    setEncounterData({
      data: getEncounterClinicVisit.data,
      type: "Clinic Visit",
    });
  }, [getEncounterClinicVisit.data]);

  useEffect(() => {
    setEncounterData({ data: getEncounterPhoneCall.data, type: "Phone Call" });
  }, [getEncounterPhoneCall.data]);

  useEffect(() => {
    setEncounterData({
      data: getEncounterProgressNote.data,
      type: "Progress Note",
    });
  }, [getEncounterProgressNote.data]);

  useEffect(() => {
    setEncounterData({ data: getOrder.data, type: "Order" });
  }, [getOrder.data]);

  useEffect(() => {
    console.log("pres", encounterData.data.prescription?.drugs);
    if (encounterData.type === "Order") setListData(getDataArr(encounterData));
    else setListData(data);
  }, [encounterData]);

  const onSubmitOrder = (data) => {
    data.sub_types = data.sub_types.map((sub_type) => sub_type);
    data.reasons = data.reasons.map((reas) => reas.id);
    delete data[
      selectedOrderType.name === "Other" ? "sub_types" : "order_subtype_string"
    ];

    console.log(data);
    setOrdersArr((oldArray) => [...oldArray, data]);
    handleOpenModal();
  };

  const onSubmitPrescription = (data) => {
    setPrescriptionArr((oldArray) => [...oldArray, ...data]);
    handleOpenPrescriptionModal();
  };
  const handleRemoveSubType = (orderIndex, subType = null) => {
    let tempArr = [];
    if (subType === null) {
      delete ordersArr[orderIndex]["order_subtype_string"];
      tempArr = [...ordersArr];
      tempArr.splice(orderIndex, 1);
    } else {
      let remainingSubTypes = ordersArr[orderIndex].sub_types.filter(
        (item) => item !== subType
      );
      tempArr = [...ordersArr];
      // if there are remaining types
      if (remainingSubTypes.length !== 0) {
        let tempObj = { ...ordersArr[orderIndex] };
        tempObj.sub_types = remainingSubTypes;
        tempArr[orderIndex] = tempObj;
      } else tempArr.splice(orderIndex, 1);
    }
    setOrdersArr(tempArr);
  };

  const getOrdersAndPrescriptionData = () => {
    let obj = null;
    if (ordersArr.length > 0) obj = { orders: ordersArr };
    if (prescriptionArr.length > 0)
      obj = { prescription: { drugs: prescriptionArr }, ...obj };
    return obj;
  };

  const onSubmit = async (data) => {
    for (let i = 0; i <= ordersArr.length; i++) {
      if (ordersArr[i]?.sub_types?.length > 0) {
        ordersArr[i].sub_types = ordersArr[i].sub_types.map(
          (subType) => subType?.id
        );
      }
    }
    let obj = getOrdersAndPrescriptionData();
    let res = {};
    if (watchedFields[0] === "Phone Call")
      res = await addEncounterPhoneCall.requestWithToast(
        t("Added successfully"),
        { ...obj, ...phoneCallObj(data) },
        patientId
      );
    else if (watchedFields[0] === "Progress Note")
      res = await addEncounterProgressNote.requestWithToast(
        t("Added successfully"),
        { ...obj, ...phoneCallObj(data) },
        patientId
      );
    else if (watchedFields[0] === "Clinic Visit")
      res = await addEncounterClinicVisit.requestWithToast(
        t("Added successfully"),
        {
          ...obj,
          ...clinicVisitObj(data),
          general_examination: generalExaminationId,
          special_examination: specialExaminationId,
        },
        patientId
      );
    if (res.ok) {
      setPageNumber(1);
      setViewMode("cards");
      setResetForm(true);
      setOrdersArr({});
      setPrescriptionArr([]);
      setGeneralExaminationId(null);
      setSpecialExaminationId(null);
    }
  };

  const ordersListView = () => (
    <Grid container mt={3}>
      <Grid item xs={4} md={2}>
        <Typography style={{ fontWeight: 500, paddingTop: 8 }}>
          {" "}
          Orders
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <List dense>
          {ordersArr.length > 0 &&
            ordersArr.map(
              (order, index) =>
                (order.sub_types?.length > 0 || order.order_subtype_string) && (
                  <Box
                    sx={{
                      p: 1,
                      border: "1px solid grey",
                      borderRadius: "10px",
                      m: 1,
                    }}
                  >
                    {order.sub_types?.length > 0 &&
                      order.sub_types.map((subType, subIndex) => (
                        <ListItem
                          key={subIndex}
                          secondaryAction={
                            <IconButton
                              onClick={() =>
                                handleRemoveSubType(index, subType)
                              }
                              edge="end"
                              aria-label="delete"
                            >
                              <DeleteIcon style={{ color: "#003468" }} />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={`${subType?.order_type_string}(${subType?.name})`}
                          />
                        </ListItem>
                      ))}
                    {order.order_subtype_string && (
                      <ListItem
                        // key={index}
                        secondaryAction={
                          <IconButton
                            onClick={() => handleRemoveSubType(index)}
                            edge="end"
                            aria-label="delete"
                          >
                            <DeleteIcon style={{ color: "#003468" }} />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={order.order_subtype_string} />
                      </ListItem>
                    )}
                  </Box>
                )
            )}
        </List>
      </Grid>
    </Grid>
  );

  const handleRemovePrescription = (prescription) => {
    let index = prescriptionArr.indexOf(prescription);
    prescriptionArr.splice(index, 1);
    setPrescriptionArr([...prescriptionArr]);
  };

  const prescriptionsListView = () => (
    <Grid container mt={3}>
      <Grid item xs={4} md={2}>
        <Typography style={{ fontWeight: 500, paddingTop: 8 }}>
          Prescription
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <List dense>
          {prescriptionArr.length > 0 &&
            prescriptionArr.map((prescription, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    onClick={() => handleRemovePrescription(prescription)}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon style={{ color: "#003468" }} />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`${prescription?.generic_name}, ${prescription?.route_administration}
                  for ${prescription?.rate} ${prescription?.rate_type} - ${prescription?.frequency_rate} doses /${prescription?.frequency_type}`}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );

  const handleSubtypeSearch = (query) => {
    if (ordersWatchedFields[0] !== "")
      getOrderSubTypesList.request(
        JSON.parse(ordersWatchedFields[0]).id,
        `?search=${query}`
      );
  };

  //printing
  const dataToPrint = {
    header: {
      title: "Encounter",
      left: [
        moment(encounterData?.data?.created_at).format("DD MMM YYYY"),
        `Doctor: ${encounterData?.data?.practitioner?.full_name}`,
      ],
      right: [
        `Specialty: ${encounterData?.data?.practitioner?.specialty?.name}`,
        `Type: ${encounterData?.type}`,
      ],
    },
    content:
      encounterData.type === "Order"
        ? listData.map((item) =>
            item.fieldType === "labelField"
              ? { type: "label", label: item.data, data: null }
              : item
          )
        : [
            ...data?.filter(
              (item) =>
                item.label !== "Prescription" &&
                item.label !== "General Examination" &&
                !item.cardItem
            ),
            encounterData?.data?.prescription && {
              label: "Prescription",
              data: encounterData.data.prescription.drugs?.map(
                (item) =>
                  `${item.generic_name}, ${item.route_administration} 
                   ${item.num_units} unit, ${item.frequency_rate} doses ${
                    item.frequency_type !== "once" ? "every " : ""
                  }${item.frequency_type}, for ${item.rate} ${item.rate_type}`
              ),
            },
            // encounterData?.data?.general_examination && {
            //   label: "General Examination",
            //   data: generalExaminationListData,
            // },
          ].filter((item) => item),
  };

  return (
    <>
      <Stack
        gap={3}
        style={{
          display: encounterViewMode !== "encounter" && "none",
        }}
      >
        <CebForm
          onSubmit={onSubmit}
          fields={fields}
          listData={listData}
          cardsData={getEncountersList.data}
          cardItems={encounterCardItems}
          fieldsToWatch={{ name: ["encounterType"], defaultValue: [""] }}
          getWatchedFields={(fields) => setWatchedFields(fields)}
          viewModeState={viewModeKey}
          handleViewButton={handleViewCard}
          resetState={[resetForm, setResetForm]}
          loading={
            getEncountersList.loading ||
            getEncounterClinicVisit.loading ||
            getEncounterPhoneCall.loading ||
            getOrder.loading ||
            addEncounterClinicVisit.loading ||
            addEncounterPhoneCall.loading
          }
          chosenCardId={chosenCardId}
          requestErrors={
            addEncounterClinicVisit.error || addEncounterPhoneCall.error
          }
          module={module}
          dataToPrint={dataToPrint}
          totalCount={getEncountersList.count}
          paginationState={[pageNumber, setPageNumber]}
        >
          {ordersArr.length > 0 && ordersListView()}
          {prescriptionArr.length > 0 && prescriptionsListView()}
        </CebForm>

        <Modal open={openModal} handleOpen={handleOpenModal}>
          <CebForm
            modalForm
            modalTitle="New Order"
            handleOpenModal={handleOpenModal}
            onSubmit={onSubmitOrder}
            viewModeState={[viewMode, setViewMode]}
            fields={getFields(
              getOrderTypesList.data,
              getOrderSubTypesList.data,
              false, //admissionCondition
              getDiseasesField,
              selectedOrderType,
              handleSubtypeSearch
            ).filter((f) => f)}
            fieldsToWatch={{ name: ["orderType"], defaultValue: [""] }}
            getWatchedFields={(fields) => setOrdersWatchedFields(fields)}
            istData={[]}
            addInList={true}
            resetState={[resetForm, setResetForm]}
            formWidth={700}
          />
        </Modal>
        {/* prescrition form modal */}
        <DrugsForm
          openModal={openPrescriptionModal}
          handleOpenModal={handleOpenPrescriptionModal}
          onSubmitHandler={onSubmitPrescription}
        />
      </Stack>

      {viewMode === "form" && (
        <>
          <Stack
            gap={3}
            style={{
              display: encounterViewMode !== "generalExamination" && "none",
            }}
          >
            <GeneralExamination
              generalExaminationId={generalExaminationId}
              encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
            />
          </Stack>
          <Stack
            gap={3}
            style={{
              display: encounterViewMode !== "specialExamination" && "none",
            }}
          >
            <SpecialExamination
              encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
              specialExaminationId={specialExaminationId}
              specialExaminationForm={getSpecialExaminationForm.data}
            />
          </Stack>
        </>
      )}
      {viewMode === "list" &&
        encounterViewMode === "generalExamination" &&
        getEncounterClinicVisit.data.general_examination && (
          <GeneralExaminationDetailView
            generalExaminationId={
              getEncounterClinicVisit.data.general_examination
            }
            encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
          />
        )}
      {viewMode === "list" &&
        encounterViewMode === "specialExamination" &&
        getEncounterClinicVisit.data.special_examination && (
          <SpecialExaminationDetailView
            specialExaminationId={
              getEncounterClinicVisit.data.special_examination
            }
            encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
          />
        )}
    </>
  );
}
