import React, { useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CebList from "../../../components/Form/CebList";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import { modules } from "../../../config/constants";
import usePermissions from "../../../hooks/usePermissions";
import { t } from "i18next";

const module = modules.PROCEDURE;
const subModule = modules.PROCEDURE_SURGICAL_DATA;

export default function ProcedureSurgicalData() {
  const { patientId, procedureId, age, gender } = useParams();
  const [viewMode, setViewMode] = useState();
  const [surgicalData, setSurgicalData] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const addProcedureSurgicalData = useApi(mainApi.addProcedureSurgicalData);

  //permissions
  const permissions = usePermissions(module, subModule);

  const handleProcedureView = async () => {
    const res = await mainApi.getProcedureSurgicalData(patientId, procedureId);
    if (res.ok) {
      if (res.data?.data) {
        setSurgicalData(res.data.data);
        setViewMode("list");
      } else {
        setViewMode("form");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleProcedureView();
  }, [addProcedureSurgicalData.data]);

  const fields = [
    {
      name: "position",
      label: "Position",
      multiline: true,
    },
    {
      name: "monitoring",
      label: "Monitoring",
      multiline: true,
    },
    {
      name: "procedure_steps",
      label: "Procedure steps",
      multiline: true,
    },
    {
      name: "intraoperative_incidents",
      label: "Intraoperative Incidents",
      multiline: true,
    },
  ];

  const data = [
    {
      label: "Position",
      data: surgicalData?.position,
      multiline: true,
    },
    {
      label: "Monitoring",
      data: surgicalData?.monitoring,
      multiline: true,
    },
    {
      label: "Procedure steps",
      data: surgicalData?.procedure_steps,
      multiline: true,
    },

    {
      label: "Intraoperative Incidents",
      data: surgicalData?.intraoperative_incidents,
      multiline: true,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addProcedureSurgicalData.requestWithToast(
      t("Added successfully"),
      patientId,
      procedureId,
      data
    );

    if (res.ok) {
      setViewMode("list");
    }
  };

  return (
    <>
      {loading && <LoadingBox loading={true} />}
      {viewMode === "form" && !mobileView && (
        <CebForm
          onSubmit={onSubmit}
          fields={fields}
          viewModeState={[viewMode, setViewMode]}
          formWidth="90%"
          cancelHandler={() =>
            navigate(`/patients/${patientId}/${age}/${gender}/procedures`)
          }
          requestErrors={addProcedureSurgicalData.error}
        />
      )}
      {viewMode === "list" && (
        <Box
          width={970}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            borderColor: "#00346880",
            p: 2,
          }}
        >
          <CebList data={data} />
        </Box>
      )}
    </>
  );
}
